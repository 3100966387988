import { defineComponent, type ReactiveComponent } from "vue-utils"
import StarRating from "./components/StarRating"

const CompleteStep: ReactiveComponent = () => {
	return () => (
		<>
			<div class="col-12 mb-1 text-center">
				<h3 class="bold">Thanks! You have completed your claim</h3>
				<p class="my-3">Your claim is complete! How easy was that?</p>
				<p class="bold">Rate your experience so far:</p>
			</div>

			<div class="col-12 my-2 text-center text-light">
				<StarRating threshold={5} />
			</div>

			<div class="col-12 mt-2 mb-5 text-center">
				<hr />
			</div>

			<div class="col-12">
				<p>
					Your unique claim reference is{" "}
					<span class="bold">
						{brand.Abbreviation}
						{App.CustomerClaim.ClaimId}
					</span>
				</p>
			</div>

			<div class="col-12 small">
				<p>Please make a note of this reference or print this page as you will need your claim number if you have any enquiries.</p>
				<p>You will have been sent an email to confirm your claim.</p>
				<p>
					<strong>
						IMPORTANT – we will keep you up to date about the progress of your claim by email. Please do the following to ensure you receive our
						emails:
					</strong>
					<ol>
						<li>Check your email now to see if you have received an email from us.</li>
						<li>If no email is there please check your 'spam' and 'promotions' folders and move any email from us to your main inbox.</li>
					</ol>
				</p>
			</div>

			<div class="col-12 my-2 text-center" style="display: none;">
				<button type="button" class="btn btn-outline-primary cancel">
					Close <i class="fa fa-close"></i>
				</button>
			</div>

			<div class="col-12 my-3 text-center">
				<i class="fa fa-3x fa-thumbs-o-up"></i>
			</div>

			<div class="col-12 my-2 text-center pt-4 small">
				<p>
					If you wish to cancel your claim please visit{" "}
					<a
						href={`/cancel/${App.CustomerClaim.UserId}/claim/${App.CustomerClaim.ClaimId}`}
						style="text-decoration:underline !important;"
						target="_blank"
					>
						this link
					</a>
					.
				</p>
			</div>
		</>
	)
}

export default defineComponent(CompleteStep)
