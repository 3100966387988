import { showTrustPilotModal } from "@/utils/miscPageUtils"
import { css } from "vite-css-in-js"
import type { StyleValue } from "vue"
import { defineComponent, inferProps, type ReactiveComponent } from "vue-utils"

interface Props {
	threshold: number
}

const styles = css`
	border: none;
	background-color: transparent;
	padding: 0 0.1rem;
	margin: 0;
	color: #fff;

	&:hover {
		color: var(--hover-colour);
		text-shadow: #fff 0 0 1.1rem;
	}
`

const StarRating: ReactiveComponent<Props> = (props) => {
	const noOfStars = 5
	const stars = [...Array(noOfStars).keys()].map((x) => ++x)
	const colours = ["#990000", "#FF6701", "#FF6701", "#FEAA00", "#00C41B"]

	async function chooseRating(score: number) {
		if (score >= props.threshold) {
			await showTrustPilotModal(App.CustomerClaim.ClaimId, App.CustomerClaim.TrustPilotSiteUrl)
		} else {
			window.open(`/feedback/unhappy?u=${App.CustomerClaim.UserId}`)
		}
	}

	function buttonStyle(score: number): StyleValue {
		return {
			"--hover-colour": getColour(score),
		}
	}

	function getColour(score: number) {
		return colours[score - 1]
	}

	return () => (
		<div>
			{stars.map((rating, index) => (
				<button key={index} onClick={() => void chooseRating(rating)} class={styles} style={buttonStyle(rating)}>
					<i class="fa fa-2x fa-star" title={`${rating.toString()} stars`}></i>
				</button>
			))}
		</div>
	)
}

export default defineComponent(StarRating, inferProps<Props>())
