import ButtonNavigation from "@/components/ButtonNavigation"
import RadioButtons from "@/components/RadioButtons"
import { goToNextStep } from "@/step-handler"
import type { Employer, EnumEntry, Nullable } from "@/types"
import type { PAYERecord } from "@/types/CompanyPAYEModels"
import { bigramSimilarity } from "@/utils/bigramSimilarity"
import { computed, ref } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import { employerContainerStyles, employerListButtonStyles, employerListStyles } from "./employerStepStyles"

const EmployerStep: ReactiveComponent = () => {
	const sufficientYears = 2
	const selectedSectorIndex = ref<number | null>(0)
	const selectedCategoryIndex = ref<number | null>(null)
	const categoryConfirmed = ref<boolean | null>(false)
	const searchValue = ref<string | null>(null)
	const companyResults = ref<PAYERecord[]>([])
	const showNotFoundButton = ref<boolean>(false)

	function nextSector() {
		//if ((selectedSectorIndex.value ?? 0) >= App.companyData.length - 1) {
		if (selectedSectorIndex.value === null) {
			selectedSectorIndex.value = 0
		} else {
			selectedSectorIndex.value = (selectedSectorIndex.value ?? -1) + 1
		}
	}

	function selectCategory(i: number) {
		if (activeEmployer.value !== null) {
			if (selectedCategoryIndex.value !== i) {
				selectedCategoryIndex.value = i
				activeEmployer.value.LookupDataSectorCategoryIndex = i
			} else {
				selectedCategoryIndex.value = null
			}

			if (selectedSectorIndex.value !== null) {
				activeEmployer.value.LookupDataSectorIdentifier = App.companyData[selectedSectorIndex.value]?.Identifier
				if (selectedCategoryIndex.value !== null) {
					activeEmployer.value.OccupationId =
						activeEmployer.value.OccupationId ?? App.companyData[selectedSectorIndex.value].Categories[selectedCategoryIndex.value].OccupationId
				}
			}
		}
	}

	function confirmCategory() {
		categoryConfirmed.value = true
	}

	function compare(searchTerm: string, content: string) {
		const l = content.length
		if (content.indexOf(searchTerm) !== -1) return (l - content?.indexOf(searchTerm)) / l

		const split = content.split(" ")
		const wordSimilarities: number[] = []
		split.forEach((s) => {
			const wordSimilarity = bigramSimilarity(s ?? "", searchTerm)
			wordSimilarities.push(wordSimilarity + 1)
		})
		if (wordSimilarities.length > 1) {
			const score =
				wordSimilarities.reduce((a, b) => {
					return a + b
				}) /
					wordSimilarities.length -
				1
			if (score > 0) return score
		}

		return 0
	}
	function sortCompare(a: PAYERecord, b: PAYERecord) {
		const search = searchValue.value?.toUpperCase() ?? ""
		if (compare(search, a.Name ?? "") > compare(search, b.Name ?? "")) {
			return -1
		} else if (compare(search, a.Name ?? "") < compare(search, b.Name ?? "")) {
			return 1
		} else {
			return 0
		}
	}
	function searchCompanies() {
		console.log(searchValue.value)
		const search = searchValue.value?.toUpperCase() ?? ""
		const Source = App.companyData[selectedSectorIndex.value ?? -1]?.Categories[selectedCategoryIndex.value ?? -1].Companies
		if (!Source) return []
		const Results = Source.filter((r) => compare(search, r.Name ?? "") > 0.18).sort((a, b) => sortCompare(a, b))
		companyResults.value = Results
		showNotFoundButton.value = true
		return true
	}
	function selectEmployer(i: number) {
		if (i !== null) {
			const selectedEmployer: PAYERecord | null = companyResults.value[i]
			if (selectedEmployer !== null && activeEmployer.value !== null) {
				activeEmployer.value.EmployerName = selectedEmployer.Name ?? ""
				activeEmployer.value.PAYEReference = selectedEmployer.Reference ?? ""
			}
		}
	}
	function checkEmployers() {
		if (activeEmployerValid.value === true) {
			if (!employerRecordsComplete.value) {
				employerIndex.value += 1
				searchValue.value = null
				selectedCategoryIndex.value = null
				categoryConfirmed.value = false
				companyResults.value = []
			} else {
				employerIndex.value = -1
			}
		}
	}
	function selectExistingRecord(i: number) {
		employerIndex.value = i
	}
	function needToAskAboutUniform(employer: Nullable<Employer>) {
		if (!employer) return true
		if (employer.OccupationId === null) return true

		const occupation = App.AllOccupations.find((o) => o.OccupationId === employer?.OccupationId)

		if ((occupation?.Deduction ?? 60) <= 60) {
			return true
		} else {
			employer.WoreUniform = true
			return false
		}
	}
	function formatStartYear(taxYear: EnumEntry, addDescription: boolean) {
		const suffix = taxYear === App.currentTaxYears[0] && addDescription ? " or before" : ""
		return `${taxYear.Key.split("/")[0]}${suffix}`
	}
	function skipStep() {
		App.CustomerClaim.TaxYearIds = App.currentTaxYears.map((e) => e.Value)
		void goToNextStep()
	}
	const categories = computed(() => {
		if (selectedSectorIndex.value !== null) {
			return selectedSectorIndex.value < App.companyData.length ? App.companyData[selectedSectorIndex.value]?.Categories : []
		} else {
			return null
		}
	})

	const employerRecordsComplete = computed(() => {
		if (App.CustomerClaim.Employers.filter((e) => e.YearCommenced === null || e.WoreUniform === null).length > 0) return false
		const d = new Date()
		const allYears = App.CustomerClaim.Employers.flatMap((emp) => emp.YearCommenced ?? 2024)
		const min = Math.min(...allYears)

		return min <= d.getFullYear() - sufficientYears
	})
	const resultsFound = computed(() => {
		return (companyResults.value ?? []).length > 0
	})
	const activeEmployer = computed(() => {
		if (employerIndex.value < 0) return null
		if (App.CustomerClaim.Employers.length < employerIndex.value + 1) {
			for (let i = App.CustomerClaim.Employers.length; i <= employerIndex.value; i++) {
				App.CustomerClaim.Employers[i] = {
					EmployerName: "",
					PAYEReference: "",
					Address: "",
					YearCommenced: null,
					Postcode: "",
					LookupDataSectorIdentifier: "",
					LookupDataSectorCategoryIndex: null,
					OccupationId: null,
					IndustryId: null,
					JobTitle: "",
					WoreUniform: null,
				}
			}
		}
		return App.CustomerClaim.Employers[employerIndex.value]
	})
	const activeEmployerValid = computed(() => {
		return activeEmployer.value?.PAYEReference && activeEmployer.value?.YearCommenced && activeEmployer.value?.WoreUniform !== null
			? true
			: false
	})
	const anotherEmployerNeeded = computed(() => {
		return !employerRecordsComplete.value
	})
	const previousEmployer = computed(() => {
		if ((employerIndex.value ?? 0) === 0) return null
		return App.CustomerClaim.Employers[employerIndex.value - 1]
	})
	const moreThanOneEmployer = computed(() => {
		return employerIndex.value > 0 ? true : false
	})
	const employerIndex = ref<number>(employerRecordsComplete.value ? -1 : 0)

	return () => (
		<>
			{App.CustomerClaim.Employers.filter((e) => e.YearCommenced && e.WoreUniform !== null).length > 0 && (
				<div class="col-12 mx-auto my-3 p-1 alert alert-success">
					<h3 class="text-dark">Employment records</h3>
					<ol class="d-flex flex-column mt-3 align-items-center p-1">
						{App.CustomerClaim.Employers.filter((e) => e.YearCommenced).map((employer, index) => (
							<li key={index} class="mb-3">
								{employer.EmployerName}, started in {employer.YearCommenced}
								<button class="btn btn-sm btn-secondary ml-3" onClick={() => selectExistingRecord(index)}>
									<i class="fa fa-edit"></i> edit
								</button>
							</li>
						))}
					</ol>
				</div>
			)}
			{!employerRecordsComplete.value && !categoryConfirmed.value && !activeEmployer.value?.EmployerName && (
				<>
					{!moreThanOneEmployer.value && (
						<div class="col-12 claim-label bold">
							<p>Does your employer work in one of these industries? Please choose the best match:</p>
						</div>
					)}
					{moreThanOneEmployer.value && (
						<div class="col-12 claim-label">
							<p class="alert alert-info">
								To ensure you have a good chance of a rebate please tell us where you worked prior to {previousEmployer.value?.EmployerName}
							</p>
						</div>
					)}
					<div class={employerContainerStyles}>
						{categories.value && (
							<ul class={employerListStyles} data-open={selectedCategoryIndex.value !== null}>
								{categories.value?.map((category, index) => (
									<li key={index}>
										<button
											class={employerListButtonStyles}
											data-active={selectedCategoryIndex.value === index}
											onClick={() => selectCategory(index)}
										>
											{category.Name}
										</button>
									</li>
								))}
								{selectedCategoryIndex.value === null && categories.value.length !== 0 && (
									<li class="none">
										<button class={employerListButtonStyles} onClick={() => nextSector()}>
											None of these...
										</button>
									</li>
								)}
								{categories.value.length === 0 && (
									<li>
										<button
											class="btn btn-outline-info"
											onClick={() => {
												skipStep()
											}}
										>
											None of these apply to me
										</button>
									</li>
								)}
							</ul>
						)}
					</div>
					{selectedCategoryIndex.value !== null && (
						<div class="col-12 text-center">
							<button class="btn btn-success" onClick={() => confirmCategory()}>
								<i class="fa fa-check" /> Confirm
							</button>
						</div>
					)}
				</>
			)}
			{categoryConfirmed.value && activeEmployer.value && !activeEmployer.value?.EmployerName && (
				<>
					<div class="col-12 claim-label bold">
						<p>Please type the name of the company you work for and tap search.</p>
					</div>
					<div class="col-12 col-md-8 mx-auto">
						<div class="input-group">
							<input
								type="text"
								class="form-control"
								v-model={searchValue.value}
								onKeypress={(e) => {
									if (e.key === "Enter") {
										searchCompanies()
										e.preventDefault()
									}
								}}
							/>
							<button type="button" class="btn-success" onClick={() => searchCompanies()}>
								<i class="fa fa-search" />
								&nbsp; Search
							</button>
						</div>
					</div>
					<div class="col-12 col-md-8 mx-auto text-center">
						<ul class="list-group ">
							{resultsFound.value && (
								<li class="list-group-item bg-success">
									<strong>Possible matches</strong> - please choose your employer.
								</li>
							)}
							{companyResults.value?.map((result, index) => (
								<li key={index} class="list-group-item text-dark" data-reference={result.Reference}>
									<button
										class={`${employerListButtonStyles} dark`}
										onClick={() => {
											selectEmployer(index)
										}}
									>
										{result.Name}
									</button>
								</li>
							))}
							{showNotFoundButton.value && (
								<li class="list-group-item bg-info">
									<button
										class="btn btn-outline-info"
										onClick={() => {
											skipStep()
										}}
									>
										Can't find your employer? Click here.
									</button>
								</li>
							)}
						</ul>
					</div>
				</>
			)}
			{activeEmployer.value && activeEmployer.value.EmployerName && (
				<>
					{needToAskAboutUniform(activeEmployer.value) && (
						<div class="col-12  mx-auto my-1 text-center bold">
							<RadioButtons
								legend={`Did you wear a uniform at ${activeEmployer.value.EmployerName}?`}
								name="wore-uniform"
								id="inp-wore-uniform"
								required
								reverse
								value={activeEmployer.value.WoreUniform}
								setValue={(value) => {
									if (activeEmployer.value) {
										activeEmployer.value.WoreUniform = value
									}
								}}
							/>
							{activeEmployer.value.WoreUniform === false && (
								<p class="col-12 col-md-6 col-lg-4 mx-auto alert alert-danger">
									<h5>Are you sure you didn’t wear a uniform?</h5>
									<p>
										<small>
											This can be a t-shirt with a company logo, high viz gear or a recognised uniform such as a nurse or police officer.
										</small>
									</p>
								</p>
							)}
						</div>
					)}

					<div class="col-12 text-center mx-auto my-1">
						<span class="bold">What year did you start working at {activeEmployer.value.EmployerName}?</span>
						<div class="row">
							<div class="col-12 col-md-6 col-lg-4 mx-auto mt-2">
								<select
									class="form-control"
									onChange={(e) => {
										if (activeEmployer.value) {
											const y = parseInt((e.target as HTMLSelectElement).value)
											activeEmployer.value.YearCommenced = y
										}
									}}
								>
									<option>Select year</option>
									{App.currentTaxYears.map((taxYear) => (
										<option
											value={formatStartYear(taxYear, false)}
											selected={activeEmployer.value?.YearCommenced === parseInt(taxYear.Key.split("/")[0])}
											key={taxYear.Key}
										>
											{formatStartYear(taxYear, true)}
										</option>
									))}
								</select>
							</div>
						</div>
					</div>
					{anotherEmployerNeeded.value && (
						<div class="col-12 mx-auto my-3 text-center">
							<p class="col-12 col-md-6 col-lg-4 mx-auto alert alert-warning">
								<small>To ensure you have a good chance of a rebate, we require at least 2 years worth of your employment history.</small>
							</p>
							<button disabled={!activeEmployerValid.value} class="btn btn-success" onClick={() => checkEmployers()}>
								Next - add another employer &gt;
							</button>
						</div>
					)}
				</>
			)}

			{employerRecordsComplete.value && <ButtonNavigation />}
		</>
	)
}

export default defineComponent(EmployerStep)
